@font-face {
  font-family: Lexend;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("lexend.6a3891b8.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Inter-roman.var.b695afbe.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-weight: 100 900;
  font-display: block;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("Inter-italic.var.69eb0fe1.woff2") format("woff2");
}

/*# sourceMappingURL=index.ad37a2ca.css.map */
